var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _vm.isBack
        ? _c("el-row", [
            _c("div", { staticClass: "backtop" }, [
              _c("span", { on: { click: _vm.gotoBack } }, [
                _c(
                  "i",
                  {
                    staticClass: "el-icon-arrow-left",
                    staticStyle: { cursor: "pointer" },
                  },
                  [_vm._v("返回上一级")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "el-row",
        { staticClass: "table" },
        [
          _vm.RecordTit
            ? _c("h3", {
                staticClass: "title",
                domProps: { textContent: _vm._s(_vm.RecordTit) },
              })
            : _vm._e(),
          _c(
            "el-row",
            { staticClass: "row-bg" },
            [
              _c(
                "el-input",
                {
                  staticClass: "tb-search-input",
                  attrs: { placeholder: "请输入内容" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.search.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.inputVal,
                    callback: function ($$v) {
                      _vm.inputVal = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "inputVal",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px" },
                      attrs: { slot: "prepend", placeholder: "请选择" },
                      slot: "prepend",
                      model: {
                        value: _vm.select,
                        callback: function ($$v) {
                          _vm.select = $$v
                        },
                        expression: "select",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "问题标题", value: "1" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-input", {
                staticClass: "tb-sm-input",
                attrs: { placeholder: "请输入姓名" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _c("el-input", {
                staticClass: "tb-sm-input",
                attrs: { placeholder: "请输入学号" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.search.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.loginName,
                  callback: function ($$v) {
                    _vm.loginName = $$v
                  },
                  expression: "loginName",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.search },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "tb-button",
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.reset },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "tb-list",
              staticStyle: { "margin-top": "30px" },
              attrs: {
                data: _vm.dataList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "提交人ID", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.loginName))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "姓名", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属问题ID", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.problem_id))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属问题标题", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.problemName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "结果", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "type-span",
                            style:
                              "backgroundColor" +
                              ":" +
                              _vm.getStateColor(scope.row.result),
                            on: {
                              click: function ($event) {
                                return _vm.subDetail(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getSubType(scope.row.result)) +
                                " "
                            ),
                            scope.row.pass_rate && scope.row.pass_rate !== 1
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      (1 - Number(scope.row.pass_rate)).toFixed(
                                        1
                                      ) * 100
                                    ) + "%"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "详情", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-row",
                          { staticStyle: { "line-height": "25px" } },
                          [
                            _c("i", { staticClass: "el-icon-time ico-time" }),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.time) + " ms"),
                            ]),
                            _c("i", { staticClass: "el-icon-coin ico-bulk" }),
                            _c("span", [
                              _vm._v(_vm._s(scope.row.memory) + " KB"),
                            ]),
                            _c("br"),
                            _c(
                              "div",
                              { staticStyle: { "margin-left": "8px" } },
                              [
                                _c("i", { staticClass: "el-icon-document" }),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v(_vm._s(scope.row.code_length))]
                                ),
                                _vm._v(" B "),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getLanText(scope.row.language))
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("dateformat")(scope.row.in_date))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }